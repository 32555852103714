.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  max-width: 85%;
  margin: 0 auto;
  background-color: #dacac4;
  margin-top: -25vh;
  margin-bottom: 2vh;
  z-index: 2;
}

.form-h2 {
  margin: 10px;
  text-transform: uppercase;
  color: #000000;
  font-size: 1.5rem;
  font-style: italic;
}

.form-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.left-column {
  grid-column: 1;
}

.middle-column {
  grid-column: 2;
}

.right-column {
  padding-top: 30px;
  margin: 0;
  grid-column: 3;
  background-color: #955a40;
}

.info {
  text-align: left;
  padding: 20px;
  background-color: var(--main-green);
  color: var(--white);
}

.info-h2 {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1.5rem;
  font-family: 'Times New Roman', Times, serif;
  margin: 0;
  color: #fcf0df;
}

.info-details-container {
  margin: 40px 0;
}

.info-detail {
  font-weight: 600;
  color: #fcf0df;
  font-size: large;
  font-style: oblique;
  font-family: 'Times New Roman', Times, serif;
}

.info-icons-container {
  display: flex;
  width: 100%;
}

.info-icon {
  font-size: 3rem;
  margin-right: 20px;
  cursor: pointer;
}

.css-zcbmsk-MuiButtonBase-root-MuiButton-root {
  background-color: #ff914d;
}

.form-submit {
  font-size: 5vw;
  margin-top: -3vh;
  color: #2e933c;
}
