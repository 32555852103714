.intro-container {
  width: 100%;
  height: 50vh;
  justify-content: start;
  align-items: flex-start;
  text-align: left;
  color: #ffffff;
  background-image: url(../images/ContactUsBackground.jpeg);
  background-size: 100% 100%;
}

.intro-h2 {
  padding-left: 7.5%;
  padding-top: 5vh;
  color: #fcf0df;
  font-style: oblique;
  font-size: 8vh;
}

.intro-p {
  color: #fcf0df;
  font-size: 3vh;
  padding-left: 7.5%;
}

/* @media screen and (min-width: 699px) {
  .intro-container {
    justify-content: start;
    align-items: flex-start;
    text-align: left;
    padding: 10%;
    height: 25vh;
  }
} */
