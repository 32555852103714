/* .custom-tab {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;
  padding: 12px 20px;
  color: white;
} */

/* .custom-tabs-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
} */

.css-heg063-MuiTabs-flexContainer {
  display: flex;
  justify-content: space-between;
  font-size: '1.25rem';
  font-weight: 'bold';
  color: 'white';
}

.custom-tab {
  font-size: '1.25rem';
  font-weight: 'bold';
  color: 'white';
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: grab;
  font-size: 1.25rem;
}
