.footer {
  background: #91672a;
  color: rgb(4, 205, 250);
  text-align: center;
  padding: 20px 0;
  font-size: 12px;
}

.footer.footer {
  padding: 20px 0;
}

.footer a {
  color: antiquewhite;
  margin: 0 15px;
  font-size: large;
  font-weight: bold;
}

.footer .footer-bottom {
  padding-top: 20px;
  font-weight: bold;
  font-size: 16px;
}

.social-icon {
  display: inline-block;
  size: 10rem;
  background-size: cover;
  margin: 0 5px;
}

a.social-icon {
  width: 5rem;
  height: 5rem;
}
