.slider {
  width: 100%;
  /* max-width: 1600px; */
  height: 500px;
  border-radius: 10px;
  overflow: hidden;
}

.slides {
  width: 500%;
  height: 500px;
  display: flex;
}

.slides input {
  display: none;
}

.slide {
  width: 20%;
  transition: 2s;
}

.slide img {
  width: 100%;
  height: 500px;
}

.navigation-manual {
  position: absolute;
  width: 1200px;
  margin-top: -40px;
  display: flex;
  justify-content: center;
}

.manual-btn {
  border: 2px solid #23e3c9;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5s;
}

.manual-btn:not(:last-child) {
  margin-right: 40px;
}

.manual-btn:hover {
  background: #40d3dc;
}

#radio1:checked ~ .first {
  margin-left: 0;
}

#radio2:checked ~ .first {
  margin-left: -20%;
}

#radio3:checked ~ .first {
  margin-left: -40%;
}

#radio4:checked ~ .first {
  margin-left: -60%;
}

/*css for the auto navigation*/
.navigation-auto {
  position: absolute;
  display: flex;
  width: 1200px;
  justify-content: center;
  margin-top: 460px;
}

.navigation-auto div {
  border: 2px solid #23e3c9;
  padding: 5px;
  border-radius: 10px;
  transition: 0.5s;
}

.navigation-auto div:not(:last-child) {
  margin-right: 40px;
}

#radio1:checked ~ .navigation-auto .auto-btn1 {
  background: #40d3dc;
}

#radio2:checked ~ .navigation-auto .auto-btn2 {
  background: #40d3dc;
}

#radio3:checked ~ .navigation-auto .auto-btn3 {
  background: #40d3dc;
}

#radio4:checked ~ .navigation-auto .auto-btn4 {
  background: #40d3dc;
}
