.app-container {
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  height: fit-content;
  /* display: flex;
  flex-direction: column; */
  /* background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #ffeb3b 0%,
      #ffe082 8%,
      #d4a22f 30%,
      #c8a340 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #f3f3f3 0%,
      #f9f9f9 8%,
      #ffd180 25%,
      #8d7243 62.5%,
      #8d7243 100%
    ); */
  background-color: #fcefe0;
  color: #ead586; /* Yellow color for text */
  font-family: 'serif'; /* Old-fashioned font */
  z-index: -99;
}

.background {
  background-color: #362114;
}

.logo-container {
  width: 300px;
  height: 281px;
}
