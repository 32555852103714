* {
  padding: 0;
  margin: 0;
  font-family: 'open sans' sans-serif;
}
body {
  background-color: dimgray;
}

.about-1 {
  /* margin: 30px; */
  /* padding: 5px; */
  background-color: #fcf0df;
  background-repeat: no-repeat;
  background-size: cover;
}
.about-1 h1 {
  color: black;
  font-weight: bold;
}
.about-1 p {
  /* text-align: center; */
  margin-left: 300px;
  padding: 3px;
  color: black;
  font-size: 20px;
  font-style: Roboto, Helvetica, Arial, sans-serif;
}
.about-item {
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: white;
  padding: 80px, 30px;
  box-shadow: 0 0 9px rgba(0, o, 0.6);
}
.about-item i {
  font-size: 43px;
  margin: 0;
}
.about-item h3 {
  font-size: 25px;
  margin-bottom: 10px;
}
.about-item hr {
  width: 46px;
  height: 3px;
  background-color: #5fbff9;
  margin: 0, auto;
  border: none;
}
.about-item p {
  margin-top: 20px;
}
.about-item:hover {
  background-color: #5fbff9;
}
.about-item:hover i,
.about-item:hover h3,
.about-item:hover p {
  color: #fff;
}
.about-item:hover hr {
  background-color: #fff;
}
.about-item:hover i {
  transform: translateY(-20px);
}
.about-item:hover i,
.about-item:hover h3,
.about-item:hover hr {
  transition: all 4ooms ease-in-out;
}

footer {
  background: #212226;
  padding: 20px;
}
footer p {
  color: #fff;
}
